.App {
  text-align: center;
  height: 100vh;
  background-image: url("./assets/bg.jpg");
  transition: all 0.3s ease-in-out;
  background-position: center;
  background-size: cover;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.fade-in {
  background-size: 120vw 120vh;
  animation: fade-in-animation 1s ease-in forwards;
}

@keyframes fade-in-animation {
  from {
    background-size: 120vw 120vh;
  }
  to {
    background-size: 110vw 110vh;
  }
}

.fade-in2 {
  opacity: 1;
  animation: fade-in-animation2 1s ease-in forwards;
}

@keyframes fade-in-animation2 {
  from {
    opacity: 0;
    top: 100px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}

.reactive-container {
  position: relative;
  width: 100%;
  margin: 0px;
  max-width: 1114px !important;
  height: 528.219px;
}

.image-outer-box {
  width: 33%;
  height: 528.219px;
}

.contain-outer-box {
  width: 33.33333%;
  height: 528.219px;
}

.item {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: transparent;
  border: 0.5px solid white;
  list-style: none;
  transition: all 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
}

.item.chomo {
  width: 100%;
  height: 100%;

  background-color: white;
  border: 0.5px solid white;
  list-style: none;
}

.item.chocl {
  width: 100%;
  height: 100%;

  background-color: white;
  border: 0.5px solid white;
  list-style: none;
}

@media screen and (max-width: 1000px) {
  .reactive-container {
    display: inline-block !important;
    height: auto !important;
  }

  .image-outer-box {
    display: inline-block;
    width: 700px !important;
    height: 528.219px;
  }

  .contain-outer-box {
    display: inline-block;
    width: 700px !important;
    height: 528.219px;
  }
}

@media screen and (max-width: 700px) {
  .reactive-container {
    display: inline-block !important;
    background-image: url("./assets/bg.jpg");
    width: 700px !important;
    height: 100% !important;
  }

  .image-outer-box {
    background-image: url("./assets/bg.jpg");
    width: 100% !important;
  }

  .contain-outer-box {
    background-image: url("./assets/bg.jpg");
    width: 100% !important;
    height: 528.219px;
  }
}
